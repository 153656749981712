import React, {CSSProperties, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {Alert, Button, Col, Form, Input, Row, Typography} from 'antd';
import {EyeInvisibleOutlined, EyeTwoTone, IconSpin, LockOutlined, UserOutlined} from "../../icons/icons";
import {doAuth, storeToLocalStorage} from "../../tools/Tools";
import MFACodeInput from "../../components/MFACodeInput";
import Redirector from "../../tools/Redirector";
import {GoogleLogin} from '@react-oauth/google';
import DPAForm from "../authCallback/components/DPAForm";
import {AppConfig} from "../../Config";
import { theme } from "antd";
const { useToken } = theme;

const {Title} = Typography;

const token = localStorage.getItem('token');
const redirectUrl = Redirector.getUri();

const Login = () => {

	const [authenticated, setAuthenticated] = useState(false);
	const [form] = Form.useForm();
	const [error, setError] = useState<string | null>(null);
	const [showMfa, setShowMfa] = useState<boolean>(false);
	const [wait, setWait] = useState<boolean>(false);
	const [mfaError, setMfaError] = useState(false);
	const [showRegistration, setShowRegistration] = useState<boolean>(false);
	const [idToken, setIdToken] = useState<string | null>(null);

	const themeToken = useToken()?.token;

	const onFormSubmit = (values: any): void => {
		if(values === null) {
			values = form.getFieldsValue();
		}
		if(idToken !== null) {
			values['id_token'] = idToken;
			delete values['email'];
			delete values['password'];
		}
		setWait(true);
		doAuth(values)
			.then(data => {
				if (data.token) {
					storeToLocalStorage('token', data.token);
					setAuthenticated(true);
				}
			})
			.catch(err => {
				setWait(false);
				switch (err.code) {
					case "A403":
						setError(err.message);
						setShowMfa(false);
						break;
					case "M403":
						setError(null);
						!showMfa && setShowMfa(true);
						break;
					case "M404":
					case "M405":
						setError(err.message);
						setShowMfa(true);
						setMfaError(true);
						break;
					case "R404":
						setShowRegistration(true);
						setShowMfa(false);
						setMfaError(false);
						break;
					case "GL403":
						setError(err.message);
						setIdToken(null);
						break;
					default:
						setError(err.message);
						break;
				}
				localStorage.clear();
			});
	};

	const style: CSSProperties = {
		display: showMfa ? "none" : ""
	};

	return (
		token || authenticated ?
			<Navigate to={redirectUrl}/>
		:
		showRegistration ?
			<DPAForm
				loading={false}
				onAgree={(v) => {
					localStorage.setItem('agree', v.join(","));
					window.location.href = AppConfig.getGoogleAuthUrl()
			}}/>
		:
			<Row align={"top"} justify={"center"} style={{
				minHeight: '100vh',
				height: '100vh',
				alignItems: 'stretch',
				overflow: 'auto',
				backgroundImage: 'url("/bcg.svg")'
			}}>
				<Col lg={12} xl={9} md={12} sm={20} xs={24} style={{
					// background: "#fff",
					background: themeToken.colorBgContainer,
					padding: '25px 20px',
					borderRadius: "0px",
				}}>
					<Row>
						<Col span={24}>
							<div style={{
								display: 'inline-flex',
								justifyContent: 'center',
								width: '100%'
							}}>
								<IconSpin style={{fontSize: 140}}/>
							</div>
						</Col>
						<Col span={24}>
							<Title
								level={3}
								style={{
									textAlign: "center",
									marginBottom: '30px'
								}}
							>
								Sign in as personal Google account
							</Title>
						</Col>
						<Col xs={{span: 24, offset: 0}} lg={{span: 18, offset: 3}}>
							{error !== null && !showMfa ?
								<Alert message={error} type={"error"} style={{marginBottom: '24px'}}/>
								: null}
							<Form
								form={form}
								layout="vertical"
								onFinish={onFormSubmit}
							>
								{showMfa && idToken ? null : <>
								<Form.Item name="email" style={style} rules={[{required: true}]}>
									<Input
										size="large"
										placeholder="Enter your ElBackup ID"
										prefix={<UserOutlined/>}
									/>
								</Form.Item>
								<Form.Item name="password" style={style} rules={[{required: true}]}>
									<Input.Password
										size="large"
										placeholder="Enter your password"
										iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
										prefix={<LockOutlined/>}
									/>
								</Form.Item></>}
								{showMfa ?
									<Form.Item name={"mfa_code"} style={{width:'80%', margin:'0 auto'}}>
										<MFACodeInput
											title={<h3 style={{textAlign: "center"}}>MFA Required</h3>}
											numInputs={6}
											description={"Enter 6 digit code from your app"}
											hasError={mfaError}
											errorMessage={error}
											onChange={(v) => {
												setMfaError(false);
												setError(null);
											}}
										/>
									</Form.Item>
									: null}
								<Form.Item style={{textAlign: showMfa ? "center" : "left"}}>
									<Button type="primary" htmlType={'submit'} onClick={() => onFormSubmit(null) } disabled={wait} size={"large"}>
										{showMfa ? "Continue" : "Login"}
									</Button>
									{showMfa ? null :
										<div style={{
											float: "right",
											marginLeft: "15px",
											opacity: wait ? 0.5 : 1,
											pointerEvents: wait ? 'none' : 'auto'
										}}>
											<GoogleLogin
												useOneTap={true}
												theme="filled_blue"
												onSuccess={creeds => {
													if(creeds.credential !== undefined) {
														setIdToken(creeds.credential);
														onFormSubmit({
															"id_token": creeds.credential
														})
													}
												}}
												onError={() => {
													setWait(false);
												}}
											/>
										</div>}
								</Form.Item>
							</Form>
						</Col>
					</Row>
				</Col>
			</Row>
	);
};

export default Login;